
import React, { useState, useRef } from 'react';
import './App.css';

function App() {
  // State to store the email input
  const [email, setEmail] = useState('');
  // State to track if the user has been notified
  const [isNotified, setIsNotified] = useState(false); // <-- New state variable
  // Ref to directly access the input element
  const emailRef = useRef(null);

  // Function to handle the "Notify Me" button click
  const handleNotify = async () => {
    const apiUrl = "https://script.google.com/macros/s/AKfycbxrYQ-bTgLe2sITMTBlAIWPOZyt7qRZmJRzfK8o5Rb4cV9IcFtYOwjz7JrbG-s9PeQ3zg/exec";

    const response = await fetch(apiUrl, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),  // send the email as JSON data
    });

    if (response.ok) {
      console.log("Data sent successfully");
       // <-- Update the state to true if the POST request is successful
    } else {
      console.error("Error sending data");
    }
    setIsNotified(true);
  }

  return (
    <div className="App">
      <header>
        <div className="logo">ask-my-ai 🤖</div>
        {/* Added the mailto link to the button */}
        <a href="mailto:ask.my.ai.hq@gmail.com">
          <button className="contact-us-btn">Contact Us</button>
        </a>
      </header>

      <div className="container">
        <h1 className="title">Adopt an AI Personal Website <br />🤖</h1>
        <p className="description">
          <strong>ask-my-ai</strong> is an AI chatbot trained on content <i>you choose</i> to showcase yourself in ways that other personal webpages can't!
        </p>
        <div className="input-container">
        <input 
            type="email" 
            className="email-input" 
            placeholder="Email"
            ref={emailRef}
            onChange={(e) => setEmail(e.target.value)} 
          />
          <button className="notify-btn" onClick={handleNotify}>
            {isNotified ? 'Sent!' : 'Notify Me'} 
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;